<template>
  <div class="item-file grid gtc-1-auto ai-c ggap-10 p-10">
    <div class="grid gtc-auto-1-auto ggap-10">
      <!-- Icon -->
      <div class="item-file__icon flex ai-c jc-c">
        <img :src="fileIcon" />
      </div>

      <!-- Data -->
      <div class="item-file__name cut">
        <span class="cut">{{ item.name }}</span>
        <small class="flex">{{ formatBytes(item.size) }}</small>
      </div>
    </div>

    <!-- Btns -->
    <div class="item-file__actions flex ai-c ggap-10">
      <template v-if="btns.select">
        <template v-if="isSelectDisabled">
          <button
            v-if="isSelected || isFinal"
            class="btn sm green-light pl-10 pr-10 rounded cube-30"
            v-tippy="'Финальный документ'"
          >
            <BaseIcon class="ic-16 green" icon="check" />
          </button>
        </template>
        <template v-else>
          <template v-if="isLoad">
            <div class="pos-r">
              <BaseLoad class="rel sm" />
            </div>
          </template>
          <template v-else>
            <button
              v-if="isSelected || isFinal"
              class="btn sm green pl-10 pr-10 rounded cube-30"
              v-tippy="'Убрать документ из финальных'"
              @click.stop.prevent="finalDocAction(true)"
            >
              <BaseIcon class="ic-16 white" icon="check" />
            </button>
            <button
              v-else
              class="btn sm outline pl-10 pr-10 rounded cube-30"
              v-tippy="'Пометить как финальный документ'"
              @click.stop.prevent="checkFinalDocAction(false)"
            >
              <BaseIcon class="ic-16 primary" icon="check" />
            </button>
          </template>
        </template>
      </template>
      <div v-if="item.children?.id ? btns.download && (isLawyer || isSuperLawyer) : btns.download">
        <BaseLoad v-if="isDownload" class="rel sm" />
        <button
          v-else
          target="_blank"
          class="btn outline sm pl-10 pr-10 rounded cube-30"
          v-tippy="'Скачать документ'"
          @click.prevent="downloadFile('regular')"
        >
          <BaseIcon class="ic-16 primary" icon="download" />
        </button>
      </div>
      <div v-if="btns.download && item.children?.id && (isSelected || isFinal)">
        <BaseLoad v-if="isStampDownload" class="rel sm" />
        <button
          v-else
          target="_blank"
          class="btn outline sm pl-10 pr-10 rounded cube-30"
          v-tippy="'Скачать документ c водяным знаком'"
          @click.prevent="downloadFile('stamp')"
        >
          <BaseIcon class="ic-16 primary" icon="stamp" />
        </button>
      </div>
      <button
        v-if="btns.remove"
        class="btn sm pl-10 pr-10 rounded cube-30"
        v-tippy="'Удалить документ'"
        @click.stop.prevent="emits('remove', item.id)"
      >
        <BaseIcon class="ic-16 black" icon="close" />
      </button>
    </div>
  </div>
  <div class="item-file flex p-10 jc-sb ai-c" v-if="askWatermark">
    <small>Создать копию с водяным знаком?</small>
    <div class="btns flex ggap-10">
      <button class="btn primary xsm" @click="finalDocAction(false, true)"><small>Да</small></button>
      <button class="btn secondary xsm" @click="finalDocAction(false, false)"><small>Нет</small></button>
      <button class="btn xsm cancel" @click="askWatermark = false"><small>Отменить</small></button>
    </div>
  </div>
</template>

<script setup>
import fileDownload from 'js-file-download'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { defineEmits, defineProps, toRefs, computed, ref } from 'vue'
import { BaseLoad, BaseIcon } from '@/components'
import orders from '@/api/modules/orders'

// Emits
const emits = defineEmits(['remove'])

// Props
const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  btns: {
    type: Object,
    default: () => ({
      download: false,
      select: false,
      remove: true
    })
  },
  isSelectDisabled: {
    type: Boolean,
    default: false
  },
  finalDocs: {
    type: Array,
    default: () => []
  },
  orderStatus: {
    type: Object,
    default: () => ({})
  }
})

// Data
const { item, btns, isSelectDisabled, finalDocs } = toRefs(props)
const store = useStore()
const route = useRoute()
const isLoad = ref(false)
const isFinal = ref(false)
const isDownload = ref(false)
const isStampDownload = ref(false)
const askWatermark = ref(false)

// Computed
const isSelected = computed(() => {
  return finalDocs.value.findIndex((el) => el.id === item.value.id) !== -1
})
const fileExtension = computed(() => {
  return /[.]/.exec(item.value.name) ? /[^.]+$/.exec(item.value.name) : undefined
})
const fileIcon = computed(() => {
  switch (fileExtension.value[0]) {
    case 'doc':
    case 'docx':
      return require('@/assets/img/files/doc.svg')

    case 'xls':
    case 'xlsx':
      return require('@/assets/img/files/xls.svg')

    case 'zip':
    case 'application/zip':
    case 'rar':
    case 'application/rar':
      return require('@/assets/img/files/zip.png')

    case 'txt':
      return require('@/assets/img/files/txt.png')

    case 'pdf':
    case 'application/pdf':
      return require('@/assets/img/files/pdf.svg')

    case 'ppt':
      return require('@/assets/img/files/ppt.png')

    case 'jpg':
    case 'jpeg':
    case 'image/jpeg':
    case 'png':
    case 'image/png':
    case 'gif':
    case 'image/gif':
    case 'ico':
    case 'image/ico':
    case 'svg':
    case 'image/svg':
      return require('@/assets/img/files/img.png')

    default:
      return require('@/assets/img/files/doc.svg')
  }
})
const profile = computed(() => store.getters.profile)
const isSuperLawyer = computed(() => profile.value?.lawyer_role === 'Senior Lawyer')
const isLawyer = computed(() => profile.value?.lawyer_role === 'Lawyer')

// Methods
function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
function checkFinalDocAction(flag) {
  if (['docx', 'pdf'].includes(item.value?.mime)) {
    askWatermark.value = true
  } else {
    finalDocAction(flag)
  }
}
function finalDocAction(stateFinal, withWatermark) {
  if (stateFinal) deletefinalAction(stateFinal)
  else setfinalAction(stateFinal, withWatermark)
  askWatermark.value = false
}
async function setfinalAction(stateFinal, withWatermark) {
  try {
    isLoad.value = true
    withWatermark && (await orders.setWatermark(route.params.id, { document: item.value.id }))
    await orders.setFinalDocs(route.params.id, { documents: [item.value.id] })
    await store.dispatch('orders/GET_ITEM', route.params.id)
    isFinal.value = !stateFinal
  } catch (err) {
    console.log(err)
  } finally {
    isLoad.value = false
  }
}
function deletefinalAction(stateFinal) {
  isLoad.value = true
  isFinal.value = !stateFinal
  orders
    .deleteFinalDocs(route.params.id, { documents: [item.value.id] })
    .then(async () => {
      await store.dispatch('orders/GET_ITEM', route.params.id)
    })
    .finally(() => {
      isLoad.value = false
    })
}
function downloadFile(type) {
  let id = ''
  let name = ''
  if (type === 'regular') {
    isDownload.value = true
    name = item.value.name
    id = item.value.id
  } else {
    isStampDownload.value = true
    name = item.value.children?.name
    id = item.value?.children?.id
  }
  orders
    .downloadDocs(route.params.id, id)
    .then(({ data }) => {
      fileDownload(data, name)
    })
    .finally(() => {
      isDownload.value = false
      isStampDownload.value = false
    })
}
</script>

<style lang="scss" scoped>
.item-file {
  border-top: var(--b1);

  &.border-inside {
    border: 0;
  }

  &:hover {
    background-color: var(--grey-l);
  }

  &:first-child {
    border: 0;
  }

  &__icon {
    width: 26px;
    height: 100%;
    border-radius: var(--br);
  }

  &__name {
    small {
      font-size: 12px;
      color: #999;
    }
  }
}
</style>

export function preparingPeoplesList(item) {
  return new Promise((resolve) => {
    resolve({
      agreementers: item?.agreementers?.length
        ? item.agreementers.map((el) => {
            return {
              id: el.id,
              avatar: el.avatar,
              text: el.fullname || 'Без имени',
              position: el?.specialization || 'Без должности'
            }
          })
        : [],
      reviewers: item?.reviewers?.length
        ? item.reviewers.map((el) => {
            return {
              id: el.id,
              avatar: el.avatar,
              text: el.fullname || 'Без имени',
              position: el?.specialization || 'Без должности'
            }
          })
        : [],
      watchers: item?.watchers?.length
        ? item.watchers.map((el) => {
            return {
              id: el.id,
              avatar: el.avatar,
              text: el.fullname || 'Без имени',
              position: el?.specialization || 'Без должности'
            }
          })
        : []
    })
  })
}
export function preparingPeoplesSingle(item) {
  return new Promise((resolve) => {
    resolve({
      responsible: item?.responsible?.id
        ? {
            id: item.responsible.id,
            avatar: item.responsible.avatar,
            text: item.responsible.fullname || 'Без имени',
            position: item.responsible?.specialization || 'Без должности'
          }
        : {},
      final_boss: item?.final_boss
        ? {
            id: item.final_boss.id,
            avatar: item.final_boss.avatar,
            text: item.final_boss.fullname || 'Без имени',
            position: item.final_boss?.specialization || 'Без должности'
          }
        : {}
    })
  })
}
export function preparingPeoplesDepartments(item) {
  return new Promise((resolve) => {
    resolve({
      agreementers: item?.agreement_departments?.length
        ? item.agreement_departments.map((el) => {
            return {
              id: el?.department?.id,
              name: el?.department?.name,
              users: el?.users.map((user) => {
                return {
                  id: user.id,
                  avatar: user.avatar,
                  text: user.fullname || 'Без имени',
                  position: user?.specialization || 'Без должности'
                }
              }),
              manual_users: el?.manual_users.map((user) => {
                return {
                  id: user.id,
                  avatar: user.avatar,
                  text: user.fullname || 'Без имени',
                  position: user?.specialization || 'Без должности'
                }
              })
            }
          })
        : [],
      reviewers: item?.review_departments?.length
        ? item.review_departments.map((el) => {
            return {
              id: el?.department?.id,
              name: el?.department?.name,
              users: el?.users.map((user) => {
                return {
                  id: user.id,
                  avatar: user.avatar,
                  text: user.fullname || 'Без имени',
                  position: user?.specialization || 'Без должности'
                }
              }),
              manual_users: el?.manual_users.map((user) => {
                return {
                  id: user.id,
                  avatar: user.avatar,
                  text: user.fullname || 'Без имени',
                  position: user?.specialization || 'Без должности'
                }
              })
            }
          })
        : [],
      watchers: item?.watcher_departments?.length
        ? item.watcher_departments.map((el) => {
            return {
              id: el?.department?.id,
              name: el?.department?.name,
              users: el?.users.map((user) => {
                return {
                  id: user.id,
                  avatar: user.avatar,
                  text: user.fullname || 'Без имени',
                  position: user?.specialization || 'Без должности'
                }
              }),
              manual_users: el.manual_users.map((user) => {
                return {
                  id: user.id,
                  avatar: user.avatar,
                  text: user.fullname || 'Без имени',
                  position: user?.specialization || 'Без должности'
                }
              })
            }
          })
        : []
    })
  })
}

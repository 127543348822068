export default [
  {
    path: 'settings',
    component: () => import(/* webpackChunkName: "page-settings" */ '@/views/settings/index.vue'),
    children: [
      {
        path: '',
        meta: {
          title: 'Управление - уведомления'
        },
        component: () =>
          import(/* webpackChunkName: "page-settings-notifications" */ '@/views/settings/notifications.vue')
      },
      {
        path: 'departments',
        meta: {
          title: 'Управление - отделы'
        },
        component: () =>
          import(/* webpackChunkName: "page-settings-departments" */ '@/views/settings/departments/index.vue'),
        children: [
          {
            path: 'add',
            meta: {
              title: 'Управление - отделы - добавить'
            },
            component: () =>
              import(
                /* webpackChunkName: "page-settings-departments-add" */ '@/views/settings/departments/department-add.vue'
              )
          },
          {
            path: 'edit/:id',
            meta: {
              title: 'Управление - отделы - редактировать'
            },
            component: () =>
              import(
                /* webpackChunkName: "page-settings-departments-edit" */ '@/views/settings/departments/department-edit.vue'
              )
          }
        ]
      },
      {
        path: 'statuses',
        meta: {
          title: 'Управление - статусы'
        },
        component: () => import(/* webpackChunkName: "page-settings-statuses" */ '@/views/settings/statuses/index.vue'),
        children: [
          {
            path: 'add',
            meta: {
              title: 'Управление - статусы - добавить'
            },
            component: () =>
              import(/* webpackChunkName: "page-settings-statuses-add" */ '@/views/settings/statuses/status-add.vue')
          },
          {
            path: 'edit/:id',
            meta: {
              title: 'Управление - статусы - редактировать'
            },
            component: () =>
              import(/* webpackChunkName: "page-settings-statuses-edit" */ '@/views/settings/statuses/status-edit.vue')
          }
        ]
      }
    ]
  }
]

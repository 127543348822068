import axios from '../axios'

class NotificationsApi {
  count() {
    return axios.get(`/api/user-notification/lawyer-count`)
  }
  getAll(page = 1, filters, sort = 'desc') {
    let url = `?page=${page}&sort=${sort}`
    if (filters) {
      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key)) {
          const element = filters[key]
          if (key === 'is_read') {
            url = url + `&${key}=${element}`
          } else {
            for (let ind = 0; ind < element.length; ind++) {
              const el = element[ind]
              url = url + `&${key}[]=${el}`
            }
          }
        }
      }
    }
    return axios.get(`/api/user-notification/lawyer${url}`)
  }
  get(id) {
    return axios.get(`/api/user-notification/lawyer/${id}`)
  }
  updateAll() {
    return axios.patch(`/api/user-notification/lawyer`)
  }
  update(id, payload) {
    return axios.post(`/api/user-notification/lawyer/${id}`, payload)
  }
}

export default new NotificationsApi()

import axios from '../axios'

class NotificationsSettingsApi {
  get() {
    return axios.get(`/api/user-notification`)
  }
  update(payload) {
    return axios.post(`/api/user-notification`, payload)
  }
}

export default new NotificationsSettingsApi()

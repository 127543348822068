<template>
  <button class="ddown__btn" type="button" :class="currentBtnClass" @click.prevent="toggleAction" :data-btn="uniqId">
    <slot name="btn" />
  </button>
  <teleport to="body">
    <div v-if="isOpen" class="ddown__body box" :data-body="uniqId" v-click-outside="closeAction">
      <slot name="body" />
    </div>
  </teleport>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { getCurrentInstance, ref, toRefs, defineProps, watchEffect, watch, defineExpose } from 'vue'
import { calcCoords } from '@/plugins'

// Props
const props = defineProps({
  btnClass: {
    type: String,
    default: 'btn sm'
  },
  btnActiveClass: {
    type: String,
    default: 'active'
  },
  direction: {
    type: String,
    default: 'ltr'
  }
})

// Data
const { btnClass, btnActiveClass, direction } = toRefs(props)
const route = useRoute()
const isOpen = ref(false)
const currentBtnClass = ref(btnClass.value)
const uid = getCurrentInstance().uid
const uniqId = 'ddown-' + uid

// Watch
watchEffect(() => {
  if (isOpen.value) currentBtnClass.value = btnClass.value + ' ' + btnActiveClass.value
  else currentBtnClass.value = btnClass.value
})
watch(
  () => route.fullPath,
  () => {
    isOpen.value = false
  }
)

// Methods
async function toggleAction() {
  isOpen.value = !isOpen.value
  calcCoords(uniqId, direction.value)
}
function closeAction() {
  isOpen.value = false
}

defineExpose({
  closeAction
})
</script>

<style lang="scss" scoped>
.ddown {
  &__body {
    position: fixed;
    z-index: 2001;
    box-shadow: var(--box-shadow);
  }
}
</style>

<template>
  <teleport to="body">
    <div class="modal" id="modal" @scroll="scrollAction">
      <div :id="'modal__content-' + uid" class="modal__content flex fd-c">
        <!-- Head -->
        <div class="modal__content-head grid gtc-1-auto ggap-20 ai-c pl-20 pr-20">
          <div class="modal__content-head-data">
            <slot name="head" />
          </div>
          <button @click.stop="closeAction" class="btn transparent-grey pl-10 pr-10">
            <BaseIcon class="ic-20 red" icon="close" />
          </button>
        </div>

        <!-- Body -->
        <div class="modal__content-body f-1">
          <slot />
        </div>

        <!-- Foot -->
        <div v-if="isFoot" class="modal__content-foot pl-20 pr-20 grid"><slot name="foot" /></div>
      </div>

      <div class="modal__close" :style="closeHeight ? { height: closeHeight + 'px' } : ''" @click.stop="closeAction" />
    </div>
  </teleport>
</template>

<script setup>
import { defineEmits, defineProps, ref, onMounted, toRefs, getCurrentInstance, computed } from 'vue'
import { BaseIcon } from '@/components'
import { $busEmit } from '@/plugins'

// Emits
const emits = defineEmits(['close'])

// Props
const props = defineProps({
  isFoot: {
    type: Boolean,
    default: true
  }
})

// Data
const { isFoot } = toRefs(props)
const closeHeight = ref(0)
const uid = getCurrentInstance().uid
const el = computed(() => document.querySelector('#modal__content-' + uid))

// Methods
function closeAction() {
  emits('close')
}
function scrollAction() {
  $busEmit('scrollAction')
  if (el.value?.scrollHeight >= closeHeight.value) setHeight()
}
function setHeight() {
  closeHeight.value = el.value?.scrollHeight ? el.value.scrollHeight + 100 : 0
}

// Mounted
onMounted(() => {
  if (el?.value) el.value?.addEventListener('DOMSubtreeModified', setHeight, false)
})
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  overflow: auto;
  padding: 0;
  display: grid;
  z-index: 1000;

  &::before,
  &::after {
    content: '';
    display: block;
    width: 100px;
    height: 20px;
    margin: auto;
  }

  &__content {
    height: max-content;
    background-color: var(--box);
    border-radius: var(--br);
    width: fit-content;
    margin: 30px auto;

    &-head {
      border-bottom: var(--b1);
      height: 50px;
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: var(--box);
      border-radius: var(--br) var(--br) 0 0;
    }
    &-foot {
      border-top: var(--b1);
      min-height: 50px;
      position: sticky;
      bottom: 0;
      z-index: 1;
      background-color: var(--box);
      border-radius: 0 0 var(--br) var(--br);
    }
  }

  &__close {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100vh;
  }
}
</style>

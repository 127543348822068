import axios from '../axios'

class AgreementsApi {
  getAll(orderId, page = '1') {
    return axios.get(`/api/lawyer-request/${orderId}/agreements?page=${page}`)
  }
  comment(orderId, payload) {
    return axios.post(`/api/lawyer-request/${orderId}/agree`, payload)
  }
}

export default new AgreementsApi()

<template>
  <teleport to="body">
    <div v-if="isOpen" class="modal flex ai-c jc-c" @scroll="scrollAction" @click="isOpen = false">
      <!-- v-click-outside="() => (isOpen = false)" -->
      <img :src="imgSrc" />
    </div>
  </teleport>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, computed } from 'vue'
import { $busOn } from '@/plugins'

// Data
const isOpen = ref(false)
const imgSrc = ref('')
const closeHeight = ref(0)
const uid = getCurrentInstance().uid
const el = computed(() => document.querySelector('#modal__content-' + uid))

// Created
$busOn('imgAction', (url) => {
  isOpen.value = true
  imgSrc.value = url
})

// Mounted
onMounted(() => {
  if (el?.value) el.value?.addEventListener('DOMSubtreeModified', setHeight, false)
})

// Methods
function scrollAction() {
  if (el.value?.scrollHeight >= closeHeight.value) setHeight()
}
function setHeight() {
  closeHeight.value = el.value?.scrollHeight ? el.value.scrollHeight + 100 : 0
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  padding: 0;
  z-index: 99999;

  img {
    max-width: 90vw;
    max-height: 90vh;
    border-radius: var(--br);
  }
}
</style>

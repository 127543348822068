import templates from '@/api/modules/templates'

const defaultState = () => {
  return {
    rows: [],
    pageCount: null,
    page: 1,
    filters: { name: '', projects: [] }
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    SET_ROWS(state, payload) {
      state.rows = payload
    },
    PUSH_ITEMS(state, payload) {
      state.rows = [...state?.rows, ...payload]
    },
    SET_PAGES(state, payload) {
      state.page = payload?.current_page
      state.pageCount = payload?.last_page
    },
    SET_FILTERS(state, payload) {
      state.filters = payload
    },
    SET_ITEM(state, payload) {
      const index = state.rows.findIndex((item) => item.id === payload.id)
      if (index !== -1) state.rows.splice(index, 1, payload)
    },
    CHANGE_ITEM(state, payload) {
      const index = state.rows.findIndex((item) => item.id === payload.id)
      if (index !== -1) state.rows[index] = payload
    }
  },
  actions: {
    async GET_ROWS({ state, commit }) {
      const { name, projects } = state?.filters
      const { data } = await templates.getAll(1, name, projects)
      commit('SET_ROWS', data?.data)
      commit('SET_PAGES', data?.meta)
    },
    async GET_MORE_ROWS({ state, commit }) {
      const { name, projects } = state?.filters
      const { data } = templates.getAll(state?.page + 1, name, projects)
      commit('PUSH_ITEMS', data?.data)
      commit('SET_PAGES', data?.meta)
    }
  },
  getters: {
    pageCount: (s) => s.pageCount,
    filters: (s) => s.filters,
    page: (s) => s.page,
    rows: (s) => s.rows
  }
}

<template>
  <!-- Link -->
  <a v-if="isLink" :href="profileLink + item.id" class="item-user flex ai-c ggap-10 p-5 pr-10 link">
    <div class="cover ava-30">
      <img :src="userAvatar" />
    </div>
    <div class="grid">
      <div class="cut">{{ item.text }}</div>
      <small class="fs-10 t-grey-dark">{{ item.position }}</small>
    </div>
    <BaseIcon v-if="isPinned" class="ic-16 grey" v-tippy="'Закреплено'" icon="check-circle" />
  </a>

  <!-- No link -->
  <div v-else class="item-user flex ai-c ggap-10 p-5">
    <div class="cover ava-30">
      <img :src="userAvatar" />
    </div>
    <div class="grid pr-10">
      <div class="cut">{{ item.text }}</div>
      <small class="fs-10 t-grey-dark">{{ item.position }}</small>
    </div>
    <BaseIcon v-if="isPinned" class="ic-16 grey" v-tippy="'Закреплено'" icon="check-circle" />
    <button v-if="isRemove" class="btn sm rounded cube-24" @click.prevent.stop="emits('remove', item.id)">
      <BaseIcon class="ic-14 black" icon="close" />
    </button>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, toRefs, inject } from 'vue'
import { BaseIcon } from '@/components'

// Emits
const emits = defineEmits(['remove'])

// Props
const props = defineProps({
  item: {
    type: Object,
    default: () => ({
      id: 0,
      avatar: '',
      text: '',
      position: ''
    })
  },
  isRemove: {
    type: Boolean,
    default: true
  },
  isLink: {
    type: Boolean,
    default: false
  },
  isPinned: {
    type: Boolean,
    default: false
  }
})

// Data
const { item } = toRefs(props)
const profileLink = inject('profileLink')

// Computed
const userAvatar = computed(() => {
  return item.value?.avatar ? item.value.avatar : require('@/assets/img/ava.svg')
})
</script>

<style lang="scss" scoped>
.item-user {
  background-color: var(--box);
  border-radius: var(--br);
  border: var(--b1);

  &.link {
    text-decoration: none;

    &:hover {
      border-color: var(--primary);
    }
  }
}
</style>

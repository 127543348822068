<template>
  <div class="form-item grid editor" :class="{ required: required, disabled: disabled }" :data-success="isSuccess">
    <label v-if="label" class="form-item__label">
      <BaseIcon v-if="helper" class="form-item__help ic-14 grey" icon="alert" v-tippy="helper" />
      {{ label }}
    </label>
    <ckeditor :editor="editor.type" :config="editor.config" :disabled="disabled" v-model="propValue" />
  </div>
</template>

<script setup>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { defineEmits, defineProps, toRefs, computed } from 'vue'
import { BaseIcon } from '@/components'

// Emits
const emits = defineEmits(['update:modelValue'])

// Props
const props = defineProps({
  label: {
    type: String,
    default: 'Label'
  },
  helper: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: 'Placeholder'
  },
  required: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: String,
    default: ''
  }
})

// Data
const { label, placeholder, required, modelValue } = toRefs(props)
const editor = {
  type: ClassicEditor,
  config: {
    toolbar: false,
    placeholder: placeholder.value || 'Комментарий...',
    mediaEmbed: {
      previewsInData: true
    }
  }
}

// Computed
const propValue = computed({
  get: () => modelValue.value,
  set: (val) => emits('update:modelValue', val)
})
const isSuccess = computed(() => !!propValue.value)
</script>

<style lang="scss">
.editor {
  &.big {
    .ck-editor__main > .ck-editor__editable {
      min-height: 100px;
    }
  }

  .ck {
    &-editor__top {
      display: none !important;
    }

    &-editor__main > .ck-editor__editable {
      word-break: break-all;
      max-height: 200px;
      border: var(--b1) !important;
      border-radius: var(--br) !important;
      background-color: var(--form) !important;
      padding: 5px 10px !important;

      &:hover {
        border: 1px var(--bcolor-d) solid !important;
        box-shadow: inset 0 0 0 1px var(--bcolor-d) !important;
      }
      &.ck-focused {
        border: 1px var(--primary) solid !important;
        box-shadow: inset 0 0 0 1px var(--primary) !important;
      }

      p {
        margin: 5px 0 !important;

        & > .image-inline {
          margin: 10px 0;
        }
      }
    }
  }
}
</style>

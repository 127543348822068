<template>
  <div class="snowfall" v-if="christmasTime && canSeeSnow">
    <div class="snow" v-for="(item, index) in 200" :key="index"></div>
  </div>
  <Toast />
  <ModalImages />
  <a
    href="https://t.me/aslankarim"
    target="_blank"
    class="tg-helper btn primary rounded cube-30"
    v-tippy="'Нашли ошибку или есть вопросы?<br>Пишите в наш телеграм бот, мы поможем!'"
  >
    <BaseIcon class="ic-18 white" icon="telegram" />
  </a>
  <router-view />
</template>

<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { BaseIcon, Toast, ModalImages } from '@/components'
import { computed, nextTick, onMounted, onUnmounted } from 'vue'
import moment from 'moment'

const store = useStore()
const router = useRouter()

// Computed
const timerID = computed(() => store.getters.timerID)
const timer = computed(() => store.getters.timer)
const christmasTime = computed(() => moment().month() == 11 || moment().month() == 0)
const canSeeSnow = computed(() => navigator?.userAgentData?.brands?.length)

// Created
document.body.addEventListener('click', function () {
  store.commit('SET_TIMER', 7200000)
  clearInterval(timerID.value)
  setTimerLogout()
})
setTimerLogout()
onMounted(() => checkAuth())
onUnmounted(() => clearInterval(timerID.value))

// Methods
async function checkAuth() {
  const getParams = new URLSearchParams(window.location.search)

  if (getParams.has('data')) {
    const data = atob(getParams.get('data'))
    const token = typeof JSON.parse(data) === 'string' ? JSON.parse(data) : data
    localStorage.setItem(`${store.getters.prefix}_token`, token)

    const decodedData = JSON.parse(token)
    store.commit('SET_TOKEN', decodedData)

    await nextTick()
    setTimeout(async () => {
      await store.dispatch('GET_PROFILE')
      router.replace({ query: [] })
    }, 500)
  } else {
    if (!localStorage.getItem(`${store.getters.prefix}_token`)) {
      localStorage.removeItem(`${store.getters.prefix}_token`)
      router.replace({ query: [] })
      await nextTick()
      const data = { from: window.location.href.replace(window.location.search, ''), token: null }
      const encodedData = btoa(JSON.stringify(data))
      window.location.replace(process.env.VUE_APP_INTRA + '?data=' + encodedData)
    } else {
      await store.dispatch('GET_PROFILE')
    }
  }
}
function setTimerLogout() {
  const ID = setInterval(() => {
    if (timer.value - 1000 >= 0) {
      store.commit('SET_TIMER', timer.value - 1000)
    } else {
      clearInterval(timerID.value)
      logoutAction()
    }
  }, 1000)
  store.commit('SET_TIMERID', ID)
}
async function logoutAction() {
  localStorage.removeItem(`${store.getters.prefix}_token`)
  localStorage.removeItem(`${store.getters.prefix}_profile`)
  await nextTick()
  store.commit('CLEAR_STATE')
  await nextTick()
  window.location.replace(process.env.VUE_APP_INTRA + '?logout')
}
</script>

<style lang="scss" scoped>
.tg-helper {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 99999;
}
</style>

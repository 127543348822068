export default [
  {
    path: 'templates',
    meta: {
      title: 'Документы'
    },
    component: () => import('@/views/templates/index.vue'),
    children: [
      {
        path: 'add',
        meta: { title: 'Добавление документа', edit: false },
        component: () => import('@/views/templates/template-edit.vue')
      },
      {
        path: 'edit/:id',
        meta: { title: 'Редактирование документа', edit: true },
        component: () => import('@/views/templates/template-edit.vue')
      }
    ]
  }
]

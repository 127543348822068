<template>
  <div class="base-load">
    <div class="base-load__content flex fdc aic jcc ggap1">
      <img src="@/assets/img/loading.svg" alt="Идет загрузка" class="m-auto" />
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseLoad'
}
</script>

<style lang="scss">
.base-load {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  // z-index: 1;
  // border-radius: var(--br);

  &__content {
    position: sticky;
    top: 0;
    max-height: 100vh;
    height: 100%;
  }

  & img {
    width: 60px;
    height: 60px;
  }

  &.fixed {
    position: fixed;
  }
  &.rel {
    position: relative;
  }
  &.md img {
    width: 40px;
    height: 40px;
  }
  &.sm img {
    width: 30px;
    height: 30px;
  }

  &.bg {
    background-color: var(--bg);
  }
  &.white {
    background-color: #fff;
  }
  &.white-transparent {
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(2px);
  }
}

.dark {
  .base-load {
    &.white,
    &.white-transparent {
      background-color: var(--box);
    }
  }
}
</style>

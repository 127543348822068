import axios from '../axios'

class OrdersApi {
  getAll(options, sort, size, page) {
    const archive = JSON.parse(localStorage?.getItem('archiveShow'))
    let url = `/api/lawyer-request?page=${page}&size=${size}`
    url += `&is_archive=${Number(archive)}`
    url += options.type ? `&type=${options.type}` : ''
    url += options?.author?.id ? `&author_id=${options.author.id}` : ''
    url += options?.responsible?.id ? `&responsible_id=${options.responsible.id}` : ''
    if (options.project?.length)
      for (let i = 0; i < options.project.length; i++) url += `&projects[]=${options.project[i]}`
    url += options.counterparty ? `&counterparties[]=${options.counterparty?.id}` : ''
    if (options.status?.length) for (let i = 0; i < options.status.length; i++) url += `&status[]=${options.status[i]}`
    if (sort?.key) url += sort?.direction === 'asc' ? `&sort_asc[]=${sort.key}` : `&sort_desc[]=${sort.key}`
    return axios.get(url)
  }
  downloadExcel(options, size, page, start, end) {
    let url = `/api/lawyer-request/download-excel?page=${page}&size=${size}&start_date=${start}&end_date=${end}`
    url += options.type ? `&type=${options.type}` : ''
    url += options?.author?.id ? `&author_id=${options.author.id}` : ''
    url += options?.responsible?.id ? `&responsible_id=${options.responsible.id}` : ''
    if (options.project?.length)
      for (let i = 0; i < options.project.length; i++) url += `&projects[]=${options.project[i]}`
    if (options.counterparty?.length) {
      for (let i = 0; i < options.counterparty.length; i++) url += `&counterparties[]=${options.counterparty[i]}`
    }
    if (options.status?.length) for (let i = 0; i < options.status.length; i++) url += `&status[]=${options.status[i]}`
    return axios.get(url)
  }
  search(id) {
    return axios.get(`/api/lawyer-request/search?s=${id}`)
  }
  get(id) {
    return axios.get(`/api/lawyer-request/${id}`)
  }
  save(payload) {
    return axios.post('/api/lawyer-request/draft', payload)
  }
  create(payload) {
    return axios.post('/api/lawyer-request', payload)
  }
  update(id, payload) {
    return axios.post(`/api/lawyer-request/${id}`, payload)
  }
  updateResponsible(id, payload) {
    return axios.patch(`/api/lawyer-request/${id}/responsible`, payload)
  }
  updatePeoples(id, payload) {
    return axios.post(`/api/lawyer-request/${id}/participant`, payload)
  }
  updatePeoplesAgree(id, payload) {
    return axios.post(`/api/lawyer-request/${id}/participant/agreement`, payload)
  }
  updatePeoplesDepartments(id, payload) {
    return axios.post(`/api/lawyer-request/${id}/participant/department`, payload)
  }
  updateStatus(id, payload) {
    return axios.patch(`/api/lawyer-request/${id}/status`, payload)
  }
  findByTitle(title) {
    return axios.get(`/orders?title=${title}`)
  }
  setFinalDocs(id, payload) {
    return axios.post(`/api/lawyer-request/${id}/final-document`, payload)
  }
  setWatermark(id, payload) {
    return axios.post(`/api/lawyer-request/${id}/watermark`, payload)
  }
  deleteFinalDocs(id, payload) {
    return axios.delete(`/api/lawyer-request/${id}/final-document`, { data: payload })
  }
  downloadDocs(id, docId) {
    return axios({
      url: `/api/lawyer-request/${id}/documents/${docId}`,
      method: 'GET',
      responseType: 'blob'
    })
  }
}

export default new OrdersApi()

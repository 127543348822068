<template>
  <div class="tab-swipe flex ggap-20">
    <RouterLink v-for="item of items" :key="item.link" :to="item.link" :append="item.append" class="tab-swipe__item">
      <slot name="item" :item="item">
        {{ item.text }}
      </slot>
    </RouterLink>
  </div>
</template>

<script setup>
import { defineProps, toRefs } from 'vue'

// Props
const props = defineProps(['items'])

// Data
const { items } = toRefs(props)
</script>

<style lang="scss" scoped>
.tab-swipe {
  border-bottom: 2px var(--bcolor) solid;

  &__item {
    background-color: transparent;
    border: 0;
    padding: 0;
    height: 30px;
    color: #999;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: var(--text);
    }

    &.router-link-exact-active {
      color: var(--text);
      box-shadow: 0 2px 0 0 var(--primary);
    }
  }
}
</style>

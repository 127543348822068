<template>
  <TransitionGroup name="fade" tag="div" class="toast grid ggap-10" @click.stop>
    <button v-if="items && items.length > 1" class="btn cancel w-100 jc-c" @click.prevent.stop="items.length = 0">
      Убрать все уведомления
    </button>
    <Item v-for="item of items" :key="item" :item="item" @remove="removeItem" />
  </TransitionGroup>
</template>

<script setup>
import { ref } from 'vue'
import { $busOn } from '@/plugins'
import Item from './Item.vue'

// Data
const items = ref([])

// Created
$busOn('setToast', (item) => setItem(item))

// Methods
function setItem(item) {
  const newItem = {
    id: (items.value.length + 1) * Math.round(new Date().getTime() / 1000),
    ...item
  }
  items.value.splice(0, 0, newItem)
}
function removeItem(item) {
  const index = items.value.findIndex((el) => el.id === item.id)
  if (index > -1) {
    items.value.splice(index, 1)
  }
}
</script>

<style lang="scss">
.toast {
  position: fixed;
  right: 10px;
  top: 0;
  z-index: 9999;
  max-height: 100vh;
  overflow: auto;
  padding: 10px 0;
}

.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}
.fade-leave-active {
  position: absolute;
}
</style>

import axios from '../axios'

class EventsApi {
  getAll(orderId, page = '1', searchText = '', types = '') {
    let url = `/api/lawyer-request/${orderId}/events?sort_created_at=desc&page=${page}`
    if (searchText) url = `${url}&s_comment=${searchText}`
    if (types) {
      const arr = types.split(',')
      let allTypes = ''
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i]
        allTypes = `${allTypes}&types[]=${element}`
      }
      url = url + allTypes
    }
    return axios.get(url)
  }
  get(orderId, id) {
    return axios.get(`/api/lawyer-request/${orderId}/events/${id}`)
  }
  update(orderId, id, payload) {
    return axios.patch(`/api/lawyer-request/${orderId}/events/${id}`, payload)
  }
  comment(orderId, payload) {
    return axios.post(`/api/lawyer-request/${orderId}/review`, payload)
  }
}

export default new EventsApi()

<template>
  <button class="switch-theme" :class="isDarkMode ? 'dark' : ''" @click="changeTheme">
    <div class="switch-theme__dot flex ai-c jc-c">
      <BaseIcon class="ic-14 white" :icon="isDarkMode ? 'moon' : 'sun'" />
    </div>
  </button>
</template>

<script setup>
import { BaseIcon } from '@/components'
import { ref } from '@vue/reactivity'

const isDarkMode = ref(false)
if (localStorage.getItem('dark-mode')) {
  changeTheme()
}

// Methods
function changeTheme() {
  isDarkMode.value = !isDarkMode.value

  if (isDarkMode.value) {
    document.documentElement.classList.add('dark')
    localStorage.setItem('dark-mode', isDarkMode.value)
  } else {
    document.documentElement.classList.remove('dark')
    localStorage.removeItem('dark-mode')
  }
}
</script>

<style lang="scss" scoped>
.switch-theme {
  $parent: &;
  position: relative;
  width: 40px;
  height: 24px;
  border: 0;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 0;

  &:hover {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3);
    transition: 0.2s;
  }
  &:active {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
    transition: 0.2s;
  }

  // &.dark {
  //   &:hover {
  //     box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3);
  //     transition: 0.2s;
  //   }
  //   &:active {
  //     box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
  //     transition: 0.2s;
  //   }
  // }

  &__dot {
    background-color: var(--primary);
    width: 20px;
    height: 20px;
    border-radius: 100px;
    position: absolute;
    left: 2px;
    top: 2px;
    transition: 0.3s;

    #{$parent}.dark > & {
      left: 18px;
      background-color: #444;
      transition: 0.3s;
    }
  }
}
</style>

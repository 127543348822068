import events from '@/api/modules/events'

const defaultState = () => {
  return {
    rows: [],
    pages: null
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    CLEAR_STATE(state) {
      Object.assign(state, defaultState())
    },
    SET_ROWS(state, payload) {
      state.rows = payload
    },
    CONCAT_ROWS(state, payload) {
      state.rows = [...payload, ...state.rows]
    },
    SET_PAGES(state, payload) {
      state.pages = payload
    },
    SET_ITEM(state, payload) {
      const index = state.rows.findIndex((item) => item.id === payload.id)
      if (index !== -1) state.rows.splice(index, 1, payload)
    },
    PUSH_ITEM(state, payload) {
      state.rows.push(payload)
    }
  },
  actions: {
    GET_ROWS({ commit }, { orderId, page, searchText, types }) {
      return events.getAll(orderId, page, searchText, types).then(({ data }) => {
        const pages = {
          ...data.meta,
          totalPages: Math.ceil(data.meta.total / data.meta.per_page)
        }
        const payload = data.data.reverse()

        commit('SET_PAGES', pages)
        if (Number(page) === 1) commit('SET_ROWS', payload)
        else commit('CONCAT_ROWS', payload)
      })
    },
    GET_ITEM({ commit }, { orderId, eventId }) {
      return events.get(orderId, eventId).then(({ data }) => {
        commit('SET_ITEM', data.data)
      })
    }
  },
  getters: {
    rows: (s) => s.rows,
    pages: (s) => s.pages
  }
}

import agreements from '@/api/modules/agreements'

const defaultState = () => {
  return {
    rows: []
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    CLEAR_STATE(state) {
      Object.assign(state, defaultState())
    },
    SET_ROWS(state, payload) {
      state.rows = payload
    },
    PUSH_ITEM(state, payload) {
      state.rows.push(payload)
    }
  },
  actions: {
    async GET_ROWS({ commit }, { orderId, page }) {
      const { data } = await agreements.getAll(orderId, page)
      const payload = data.data.reverse()
      commit('SET_ROWS', payload)
    }
  },
  getters: {
    rows: (s) => s.rows
  }
}

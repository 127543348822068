import axios from '../axios'

class CounterpartiesApi {
  getAll() {
    return axios.get('/api/lawyer-request/counterparty')
  }
  searchCounterparties(s) {
    return axios.get(`/api/lawyer-request/counterparty/search?s=${s}`)
  }
  get(id) {
    return axios.get(`/api/lawyer-request/counterparty/${id}`)
  }
}

export default new CounterpartiesApi()

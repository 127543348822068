<template>
  <teleport to="body">
    <div class="modal modal-full" @scroll="$busEmit('scrollAction')">
      <div class="modal__content grid gtr-auto-1" :class="contentClass">
        <!-- Head -->
        <div class="modal__content-head grid gtc-1-auto ggap-20 ai-c pl-20 pr-20">
          <div class="modal__content-head-data">
            <slot name="head" />
          </div>
          <button @click="closeAction" class="btn transparent-grey pl-10 pr-10">
            <BaseIcon class="ic-20 red" icon="close" />
          </button>
        </div>

        <!-- Body -->
        <slot />
      </div>

      <div class="modal__close" @click.stop="closeAction">&nbsp;</div>
    </div>
  </teleport>
</template>

<script setup>
import { defineEmits, defineProps, toRefs } from 'vue'
import { BaseIcon } from '@/components'
import { $busEmit } from '@/plugins'

// Emits
const emits = defineEmits(['close'])

// Props
const props = defineProps({
  contentClass: {
    type: Object,
    default: () => ({}),
    validator: (p) => {
      return ['object'].indexOf(typeof p) !== -1 || p === null
    }
  }
})

// Data
const { contentClass } = toRefs(props)

// Methods
function closeAction() {
  emits('close')
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  overflow: hidden;
  padding: 0;
  display: grid;
  z-index: 1000;

  &__content {
    background-color: var(--box);
    border-radius: var(--br);
    width: 95vw;
    max-width: 1350px;
    // height: 95vh;
    min-height: 300px;
    margin: auto;
    overflow: hidden;

    &.sm {
      width: 900px;
    }

    &-head {
      border-bottom: var(--b1);
      height: 50px;
      background-color: var(--box);
      border-radius: var(--br) var(--br) 0 0;
    }
  }

  &__close {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
}
</style>

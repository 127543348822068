import axios from '../axios'

class TemplatesApi {
  getAll(page, name, projects) {
    let url = `/api/lawyer-request/template?page=${page}`
    name ? (url += `&name=${name}`) : ''
    if (projects?.length) {
      for (let i = 0; i < projects.length; i++) {
        url += `&projects[]=${projects[i]?.id}`
      }
    }
    return axios.get(url)
  }
  get(id) {
    return axios.get(`/api/lawyer-request/template/${id}`)
  }
  create(payload) {
    return axios.post('/api/lawyer-request/template', payload)
  }
  update(id, payload) {
    return axios.post(`/api/lawyer-request/template/${id}`, payload)
  }
  delete(id) {
    return axios.delete(`/api/lawyer-request/template/${id}`)
  }
  downloadDocs(id) {
    return axios.get(`/api/lawyer-request/template/${id}/download`)
  }
}

export default new TemplatesApi()

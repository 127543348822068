// import { cutFullName, formatRuDateTime } from '@/plugins/helpers'

export default [
  {
    name: '№',
    key: 'id',
    filter: false,
    sort: false,
    show: true,
    disabled: false,
    width: '70px',
    flex: ''
  },
  {
    name: 'Тип заявки',
    key: 'type',
    filter: true,
    sort: false,
    show: true,
    disabled: false,
    width: 'auto',
    flex: '2'
    // formatter: (val) => (val === 'create' ? 'Создание документа' : 'Экспертиза')
  },
  {
    name: 'Инициатор',
    key: 'author',
    filter: true,
    sort: false,
    show: true,
    disabled: false,
    width: 'auto',
    flex: '1.5'
    // formatter: (val) => (val?.fullname ? cutFullName(val.fullname) : '-')
  },
  {
    name: 'Исполнитель',
    key: 'responsible',
    filter: true,
    sort: false,
    show: true,
    disabled: false,
    width: 'auto',
    flex: '1.5'
    // formatter: (val) => (val?.fullname ? cutFullName(val.fullname) : '-')
  },
  {
    name: 'Контрагент',
    key: 'counterparty',
    filter: true,
    sort: false,
    show: true,
    disabled: false,
    width: 'auto',
    flex: '1.5'
    // formatter: (val) => val?.name || '-'
  },
  {
    name: 'Проект',
    key: 'project',
    filter: true,
    sort: false,
    show: true,
    disabled: false,
    width: 'auto',
    flex: '1.5'
    // formatter: (val) => val?.project || '-'
  },
  {
    name: 'Файлы',
    key: 'documents',
    filter: false,
    sort: false,
    show: true,
    disabled: false,
    width: '60px',
    flex: ''
    // formatter: (val) => val?.length || 0
  },
  {
    name: 'Статус',
    key: 'status',
    filter: true,
    sort: false,
    show: true,
    disabled: false,
    width: 'auto',
    flex: '1'
    // formatter: (val) => val.name
  },
  {
    name: 'Роль',
    key: 'myrole',
    filter: false,
    sort: false,
    show: false,
    disabled: false,
    width: 'auto',
    flex: '1'
    // formatter: (val) => val.name
  },
  {
    name: 'Взята в работу',
    key: 'got_for_work_at',
    filter: false,
    sort: false,
    show: true,
    disabled: false,
    width: '170px',
    flex: ''
  },
  // {
  //   name: 'Дедлайн',
  //   key: 'deadline',
  //   filter: false,
  //   sort: true,
  //   show: true,
  //   disabled: false,
  //   width: 'auto',
  //   flex: '1'
  // },
  {
    name: 'Дата создания',
    key: 'created_at',
    filter: false,
    sort: true,
    show: true,
    disabled: false,
    width: '170px',
    flex: '',
    canHide: true
    // formatter: (val) => (val ? formatRuDateTime(val) : '-')
  },
  {
    name: 'Дата изменения',
    key: 'updated',
    filter: false,
    sort: false,
    show: false,
    disabled: false,
    width: '170px',
    flex: ''
    // formatter: (val) => (val ? formatRuDateTime(val) : '-')
  },
  {
    name: 'Комментарий',
    key: 'comments',
    filter: false,
    sort: false,
    show: false,
    disabled: false,
    width: '100px',
    flex: ''
  },
  {
    name: 'Сумма',
    key: 'price',
    filter: false,
    sort: false,
    show: true,
    disabled: false,
    width: 'auto',
    flex: '1'
  }
]

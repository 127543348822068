import axios from '../axios'

class DepartmentsApi {
  getAll() {
    // return axios.get('/api/departments')
    return axios.get('/api/lawyer-request/participants-templates?size=1000')
  }
  get(id) {
    return axios.get(`/api/lawyer-request/participants-templates/${id}`)
  }
  create(payload) {
    return axios.post('/api/lawyer-request/participants-templates', payload)
  }
  update(id, payload) {
    return axios.patch(`/api/lawyer-request/participants-templates/${id}`, payload)
  }
  delete(id) {
    return axios.delete(`/api/lawyer-request/participants-templates/${id}`)
  }
}

export default new DepartmentsApi()

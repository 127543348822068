import axios from '../axios'

class ProjectsApi {
  getAll() {
    return axios.get('/api/official-site/companies')
  }
  get(id) {
    return axios.get(`/api/official-site/companies/${id}`)
  }
}

export default new ProjectsApi()

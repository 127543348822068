<template>
  <BaseDropDown btn-class="user-head__btn ml-10" direction="rtl">
    <template #btn>
      <img v-if="profile" :src="getAvatar(profile.avatar)" />
    </template>
    <template #body>
      <div v-if="profile" class="grid p-5">
        <div class="user-head__profile grid gtc-auto-1 ggap-10 ai-c pl-10 pr-10 pt-5 pb-5">
          <div class="user-head__profile-ava cover">
            <img :src="getAvatar(profile.avatar)" />
          </div>
          <div class="user-head__profile-data pr-10">
            <h3 class="m-0 mb-5">{{ cutFullName(profile.fullname) }}</h3>
            <div class="fs-12">{{ profile.specialization || 'Без должности' }}</div>
          </div>
        </div>
        <hr />
        <a href="https://intra.chocofamily.kz/app" class="btn transparent-primary br-5 sm" exact-active-class="active">
          <BaseIcon class="ic-16 primary" icon="user" />
          Мой профиль
        </a>
        <!-- <RouterLink
          :to="'/user/' + profile.id + '/settings'"
          class="btn transparent-primary br-5 sm"
          exact-active-class="active"
        >
          <BaseIcon class="ic-16 primary" icon="settings" />
          Настройки
        </RouterLink>
        <hr /> -->
        <a href="#" class="btn transparent-primary br-5 sm" @click="logoutAction">
          <BaseIcon class="ic-16 primary" icon="power" />
          Выйти
        </a>
      </div>
    </template>
  </BaseDropDown>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, nextTick } from 'vue'
import { BaseIcon, BaseDropDown } from '@/components'
import { cutFullName, getAvatar } from '@/plugins/helpers'

// Data
const store = useStore()

// Computed
const profile = computed(() => store.getters.profile)

// Methods
async function logoutAction() {
  localStorage.removeItem(`${store.getters.prefix}_token`)
  localStorage.removeItem(`${store.getters.prefix}_profile`)
  await nextTick()
  store.commit('CLEAR_STATE')
  await nextTick()
  window.location.replace(process.env.VUE_APP_INTRA + '?logout')
}
</script>

<style lang="scss">
.user-head {
  &__btn {
    border: 0;
    background-color: rgba(255, 255, 255, 0.2);
    width: 30px;
    height: 30px;
    border-radius: 100px;
    padding: 0;
    overflow: hidden;

    &:hover {
      box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.5);
      // box-shadow: 0 0 0 4px var(--secondary);
    }
    // &:active {
    //   box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.1);
    // }
    &.active {
      box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.3);
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__profile {
    max-width: 250px;

    &-ava {
      width: 50px;
      height: 50px;
      border-radius: 100px;
    }
  }
}
</style>

<template>
  <div class="tabl__row grid">
    <div class="tabl__row-head flex ai-c jc-sb" :class="row.opened && 'active'" @click="toggle">
      <div class="flex ai-c ggap-10 p-10">
        <button class="btn sm rounded cube-20">
          <BaseIcon class="ic-16 black" :icon="row.opened ? 'arrow-down' : 'arrow-right'" />
        </button>
        <div class="flex ai-c ggap-10">
          <span>Сгруппированно:</span>

          <!-- Status -->
          <small
            v-if="row.groupedKey === 'status' && row.groupedItem"
            class="marker cut"
            :style="{ color: row.groupedItem.text_color, backgroundColor: row.groupedItem.background_color }"
          >
            {{ row.groupedValue }}
          </small>

          <!-- Responsible -->
          <template v-else-if="row.groupedKey === 'responsible'">
            <RouterLink v-if="row.groupedItem && row.groupedItem.id" :to="'/user/' + row.groupedItem.id">
              {{ row.groupedValue }}
            </RouterLink>
            <b v-else>Без исполнителя</b>
          </template>

          <!-- Author -->
          <template v-else-if="row.groupedKey === 'author'">
            <RouterLink v-if="row.groupedItem && row.groupedItem.id" :to="'/user/' + row.groupedItem.id">
              {{ row.groupedValue }}
            </RouterLink>
            <b v-else>Не известный инициатор</b>
          </template>

          <!-- Counterparty -->
          <template v-else-if="row.groupedKey === 'counterparty'">
            <div v-if="row.groupedItem && row.groupedItem.id">
              {{ row.groupedValue || 'Контрагент без наименования' }}
            </div>
            <b v-else>Контрагент не указан</b>
          </template>

          <!-- Project -->
          <template v-else-if="row.groupedKey === 'project'">
            <div v-if="row.groupedItem && row.groupedItem.id">
              {{ row.groupedValue || 'Проект без наименования' }}
            </div>
            <b v-else>Проект не указан</b>
          </template>

          <!-- Other -->
          <div v-else>{{ row.groupedValue || 'Не известно' }}</div>

          <!-- Counter -->
          <small class="marker white">{{ row.subrows.length }}</small>
        </div>
      </div>
      <!-- <div class="flex summ ai-c">12312323</div> -->
    </div>
    <template v-if="row.opened">
      <Row v-for="subrow of row.subrows" :key="subrow.id" :row="subrow" :cols="cols" />
    </template>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, toRefs } from 'vue'
import { BaseIcon } from '@/components'
import Row from './Row.vue'

const emits = defineEmits(['updateOpened'])
const props = defineProps({
  cols: {
    type: Object,
    default: () => ({})
  },
  row: {
    type: Object,
    default: () => ({})
  }
})
const { cols, row } = toRefs(props)

function toggle() {
  emits('updateOpened')
}
</script>

<style lang="scss" scoped>
.tabl__row {
  background-color: var(--box);

  .summ {
    width: 101px;
    padding: 0 10px;
    border-left: var(--b1);
    height: 100%;
  }

  &-head {
    position: sticky;
    top: 0;
    background-color: var(--box);
    border-bottom: var(--b1);
    cursor: pointer;

    &:hover {
      background-color: var(--grey);
    }
    &.active {
      background-color: var(--primary-el);

      .btn.rounded {
        background-color: var(--primary-el);
        border: 1px var(--primary) solid;

        & > svg {
          fill: var(--primary);
        }
      }
    }
  }
}
</style>

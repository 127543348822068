<template>
  <div class="grid ggap-30">
    <!-- Responsible -->
    <Responsible v-if="!isRead" :required="required" v-model="propValueObjUsers.responsible" />

    <!-- Other people -->
    <div class="users grid ggap-10">
      <TabSwipe :items="tabs" v-model="currentTab">
        <template #item="{ item }">
          <div class="flex ai-c ggap-5">
            <span class="fs-20">{{ item.icon }}</span>
            {{ item.text }}
            <small v-if="item.count" class="marker" :class="currentTab === item.value ? 'primary' : 'grey'">
              {{ item.count }}
            </small>
          </div>
        </template>
      </TabSwipe>

      <div v-if="currentTab !== 'final_boss' && !isRead" class="flex ai-c jc-sb ggap-10">
        <div>
          Выберите участников: <b>{{ currentTabName }}</b>
        </div>
        <button
          @click.prevent.stop="propValueIsFree[currentTab] = !propValueIsFree[currentTab]"
          class="btn transparent-grey sm pl-10"
        >
          <FormFakeSwitch :isTrue="propValueIsFree[currentTab]" />
          Свободный выбор
        </button>
      </div>

      <!-- Свободный выбор -->
      <component
        v-if="propValueIsFree[currentTab]"
        :is="currentComponentFree"
        :currentTab="currentTab"
        v-model:finalBoss="propValueObjUsers.final_boss"
        v-model:users="propValueFreeUsers[currentTab]"
      />

      <!-- Отделы -->
      <component
        v-else
        :is="currentComponentDepartment"
        :currentTab="currentTab"
        v-model:finalBoss="propValueObjUsers.final_boss"
        v-model:departments="propValueDepartmentUsers[currentTab]"
      />
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { defineEmits, defineProps, ref, defineAsyncComponent, computed, toRefs } from 'vue'
import { TabSwipe, FormFakeSwitch } from '@/components'
import Responsible from './Responsible.vue'

// Emits
const emits = defineEmits(['update:isFree', 'update:objUsers', 'update:freeUsers', 'update:departmentUsers'])

// Props
const props = defineProps({
  required: {
    type: Boolean,
    default: false
  },
  isRead: {
    type: Boolean,
    default: false
  },
  isFree: {
    type: Object,
    default: () => ({})
  },
  objUsers: {
    type: Object,
    default: () => ({
      responsible: {},
      final_boss: {}
    })
  },
  freeUsers: {
    type: Object,
    default: () => ({
      reviewers: [],
      agreementers: [],
      watchers: []
    })
  },
  departmentUsers: {
    type: Object,
    default: () => ({
      reviewers: [],
      agreementers: [],
      watchers: []
    })
  }
})

// Data
const { isRead, isFree, objUsers, freeUsers, departmentUsers } = toRefs(props)
const store = useStore()
const currentTab = ref('reviewers')
const tabs = ref([
  {
    // icon: '🤔',
    icon: '💬',
    text: 'Рассматривают',
    value: 'reviewers',
    count: computed(() => (propValueIsFree.value.reviewers ? freeUsers.value?.reviewers?.length : reviewersCount.value))
  },
  {
    // icon: '😵‍💫',
    icon: '👍',
    text: 'Согласуют',
    value: 'agreementers',
    count: computed(() =>
      propValueIsFree.value.agreementers ? freeUsers.value?.agreementers?.length : agreementersCount.value
    )
  },
  {
    // icon: '🧐',
    icon: '👀',
    text: 'Наблюдатели',
    value: 'watchers',
    count: computed(() => (propValueIsFree.value.watchers ? freeUsers.value?.watchers?.length : watchersCount.value))
  },
  {
    // icon: '😎',
    icon: '✍',
    text: 'Руководитель',
    value: 'final_boss',
    count: computed(() => (objUsers.value?.final_boss?.id ? 1 : 0))
  }
])

// Computed
const currentTabName = computed(() => {
  switch (currentTab.value) {
    case 'reviewers':
      return 'Рассмотрения'
    case 'agreementers':
      return 'Согласования'
    case 'watchers':
      return 'Наблюдения'

    default:
      return 'Не известно'
  }
})

// Computed:components
const currentComponentDepartment = defineAsyncComponent(() => {
  return isRead.value ? import('./departments/View.vue') : import('./departments/Edit.vue')
})
const currentComponentFree = defineAsyncComponent(() => {
  return isRead.value ? import('./free/View.vue') : import('./free/Edit.vue')
})

// Computed:modelValue
const propValueIsFree = computed({
  get: () => isFree.value,
  set: (val) => emits('update:isFree', val)
})
const propValueObjUsers = computed({
  get: () => objUsers.value,
  set: (val) => emits('update:objUsers', val)
})
const propValueFreeUsers = computed({
  get: () => freeUsers.value,
  set: (val) => emits('update:freeUsers', val)
})
const propValueDepartmentUsers = computed({
  get: () => departmentUsers.value,
  set: (val) => emits('update:departmentUsers', val)
})

// Computed:counts
const reviewersCount = computed(() => {
  let res = 0
  if (departmentUsers.value?.reviewers?.length) {
    for (let i = 0; i < departmentUsers.value?.reviewers.length; i++) {
      const element = departmentUsers.value?.reviewers[i]
      res = res + element.users.length + element?.manual_users?.length
    }
  }
  return res
})
const agreementersCount = computed(() => {
  let res = 0
  if (departmentUsers.value?.agreementers?.length) {
    for (let i = 0; i < departmentUsers.value?.agreementers.length; i++) {
      const element = departmentUsers.value?.agreementers[i]
      res = res + element?.users?.length + element?.manual_users?.length
    }
  }
  return res
})
const watchersCount = computed(() => {
  let res = 0
  if (departmentUsers.value?.watchers?.length) {
    for (let i = 0; i < departmentUsers.value?.watchers.length; i++) {
      const element = departmentUsers.value?.watchers[i]
      res = res + element?.users?.length + element?.manual_users?.length
    }
  }
  return res
})

// Created
setSwitchState()
store.dispatch('GET_USERS')

// Methods
function setSwitchState() {
  propValueIsFree.value.reviewers = !!freeUsers.value.reviewers.length
  propValueIsFree.value.agreementers = !!freeUsers.value.agreementers.length
  propValueIsFree.value.watchers = !!freeUsers.value.watchers.length
}
</script>

<style></style>

import notifications from '@/api/modules/notifications'
import notificationsSettings from '@/api/modules/notifications-settings'

const defaultState = () => {
  return {
    count: '',
    rows: [],
    pages: null,
    settings: {
      changing_request: true,
      changing_status: true,
      is_email: true,
      is_telegram: true,
      time_reminder: true
    }
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    SET_COUNT(state, payload) {
      state.count = payload > 99 ? '99+' : payload > 0 ? String(payload) : ''
    },
    SET_ROWS(state, payload) {
      const res = []
      for (let i = 0; i < payload.length; i++) {
        const element = payload[i]
        res.push({
          ...element,
          is_read: !!element.is_read
        })
      }
      state.rows = res
    },
    CONCAT_ROWS(state, payload) {
      state.rows = [...payload, ...state.rows]
    },
    SET_PAGES(state, payload) {
      state.pages = payload
    },
    SET_ITEM(state, payload) {
      const index = state.rows.findIndex((item) => item.id === payload.id)
      if (index !== -1) state.rows.splice(index, 1, payload)
    },
    SET_SETTINGS(state, payload) {
      const res = {}
      for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
          const element = payload[key]
          if (key !== 'id') res[key] = !!element
        }
      }
      state.settings = res
    }
  },
  actions: {
    GET_COUNT({ commit }) {
      return notifications.count().then(({ data }) => {
        commit('SET_COUNT', data.count)
      })
    },
    GET_ROWS({ commit }, { page, filters, sort = 'desc' }) {
      return notifications.getAll(page, filters, sort).then(({ data }) => {
        const pages = {
          ...data.meta,
          totalPages: Math.ceil(data.meta.total / data.meta.per_page)
        }
        const payload = data.data

        commit('SET_PAGES', pages)
        commit('SET_ROWS', payload)
        // if (Number(page) === 1) commit('SET_ROWS', payload)
        // else commit('CONCAT_ROWS', payload)
      })
    },
    GET_ITEM({ commit }, { notifyId }) {
      return notifications.get(notifyId).then(({ data }) => {
        commit('SET_ITEM', data.data)
      })
    },
    GET_SETTINGS({ commit }) {
      return notificationsSettings.get().then(({ data }) => {
        commit('SET_SETTINGS', data.data)
      })
    },
    UPDATE_SETTINGS(_, { payload, isReset }) {
      const res = isReset ? defaultState().settings : payload
      const obj = {}
      for (const key in res) {
        if (Object.hasOwnProperty.call(res, key)) {
          const element = res[key]
          obj[key] = element ? 1 : 0
        }
      }
      return notificationsSettings.update(obj)
    }
  },
  getters: {
    count: (s) => s.count,
    rows: (s) => s.rows,
    pages: (s) => s.pages,
    settings: (s) => s.settings
  }
}

import axios from '../axios'

class UsersApi {
  getAll(searchText = '') {
    const ser = searchText ? searchText : 'aaa'
    return axios.get('/api/search?fullname=' + ser)
  }
  getResponsible(searchText = '') {
    const ser = searchText ? searchText : 'aaa'
    return axios.get('/api/search?role=Lawyer&fullname=' + ser)
  }
  get(id) {
    return axios.get(`/api/lawyer-request/${id}`)
  }
}

export default new UsersApi()

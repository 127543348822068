import { createRouter, createWebHistory } from 'vue-router'

// Pages
import orders from './orders'
import settings from './settings'
import templates from './templates'

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "view-redirect" */ '@/views/redirect.vue')
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/Default.vue'),
    children: [...orders, ...settings, ...templates]
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import(/* webpackChunkName: "view-error" */ '@/views/error.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
